export const en = {
  translation: {
    // Main
    headerAppName: 'Cloud Solutions',
    appLogoAltText: 'Cloud Solutions logo',
    headerSsoAuthLinkText: 'Sign In',
    headerHomeLinkText: 'Overview',
    headerNewsLinkText: 'News',
    headerFaqLinkText: 'FAQ',
    headerAboutLinkText: 'About us',
    headerContactUsLinkText: 'Contact Us',

    // SEO
    seoWelcomePageTitle: 'Overview | Cloud Solutions Platform',
    seoAboutPageTitle: 'About us | Cloud Solutions Platform',
    seoFaqsPageTitle: 'FAQs | Cloud Solutions Platform',
    seoContactUsPageTitle: 'Contact us | Cloud Solutions Platform',
    seoCompanyName: 'Cloud Solutions Platform',
    seoCompanyDescription:
      'Cloud Solutions is a rapidly growing startup focused on developing proprietary products and providing free access to our developments for Open Source. Our goal is to create products that are used by various IT companies and developers to improve work efficiency.',
    seoCompanyKeywords:
      'Cloud Solutions startups, Cloud products for IT companies, Open source cloud platforms, Efficiency tools for developers, Cloud-based proprietary products, Startup cloud technologies, Free cloud solutions for development, Cloud services for improving work efficiency, Development tools in cloud computing, Cloud software for IT professionals, Cloud computing efficiency products, Open source tools for cloud, Cloud platform innovations, Cloud solutions for developers, Cloud technology for startups, Free cloud computing tools, Productivity cloud solutions, Cloud applications for IT work, Proprietary cloud software, Cloud computing for business efficiency, Emerging cloud technologies, Cloud resources for developers, Innovative cloud computing products, Cloud services for software development, Cloud solutions for IT efficiency, Next-gen cloud computing, Cloud computing startups, Development efficiency tools in cloud, Cloud software development tools, Cloud platforms for IT innovation',

    // WelcomePage
    heroWidgetHeroTitle: 'Platform that makes your work profitable & easier',
    benefitWidgetHeroTitle: 'Designed for your team, see for yourself',
    benefitWidgetHeroDescription:
      'Create an account to access the Cloud Solutions ecosystem. Choose the products you need and pay only for the resources you use.',

    // Benefit Open Source
    benefitWidgetFreeForOpenSourceTitle: 'Free for Open Source',
    benefitWidgetFreeForOpenSourceDescription:
      'Our company supports the development of open-source software by providing free access to all features of our platform for open-source projects. We believe that this approach not only helps to strengthen the community but also fosters innovations in technology, allowing developers to focus on creating high-quality products and refining routine processes.',

    // Benefit Efficiency
    benefitWidgetEfficiencyTitle: 'Efficiency at every step',
    benefitWidgetEfficiencyDescription:
      'Our products are designed to optimize and automate routine processes in development, saving time and resources for IT companies and developers. Using cutting-edge technologies such as artificial intelligence and machine learning, we ensure high quality and new opportunities for your projects.',

    // Benefit Leadership
    benefitWidgetLeadershipTitle: 'Leadership through innovation',
    benefitWidgetLeadershipDescription:
      'We aim to be leaders in research and development, setting new standards in addressing routine tasks. Our initiatives, focused on supporting innovation and standardizing approaches, help ensure stability and openness in the community, which is key to mutual trust and knowledge sharing.',

    widgetUseNowTitle: 'Start using now',
    widgetUseNowDescription: 'Start your free trial now and see how easy it is to  optimize your time.',
    widgetUseNowBtnCreateAccount: 'Create account',

    aboutWelcomeSectionSubTitle: 'we are a',
    aboutWelcomeSectionTitle: 'Cloud Solutions Platform',
    aboutWelcomeSectionDescription:
      'Our company is in the startup phase, directing efforts towards the development of proprietary products and providing free access to our developments for Open Source. Our goal is to offer our products to various IT companies and developers, seeking to influence the industry and contribute to innovations in technology.',

    aboutWelcomeMissionDescription:
      'Our mission is to rethink the approach to development by creating efficient products. We aim to refine routine processes, conserve resources, and develop tools to facilitate and automate their execution. Our work is focused on achieving high quality, understanding technologies, and providing new opportunities for motivated individuals.',
    aboutWelcomeMissionTitle: 'Mission',

    aboutWelcomeVisionTitle: 'Vision',
    aboutWelcomeVisionDescription:
      'Our vision is to be leaders in the field of research and development aimed at improving routine tasks. We are paving the way for new standards by exploring cutting-edge technologies such as artificial intelligence, machine learning, and server development. Our products and initiatives are geared towards fostering innovation, standardizing approaches, and ensuring stability. We believe in transparency, openness, and trust within the community, enabling us to share knowledge and skills.',

    // FAQ
    faqPageTitle: 'FAQs',
    faqPageDescription: 'Find answers to common questions about our product and services',

    // FAQs Items
    faqItemAboutPlatformAndSolutionsTitle: 'What is Cloud Solutions and what problems does it help solve?',
    faqItemAboutPlatformAndSolutionsDescription:
      'Cloud Solutions is an integrated platform that connects a variety of products developed by our company into a single ecosystem. The main advantage of our approach is the flexibility in choosing the necessary products and the ease of data exchange between them without the need for additional settings or complex integrations. By registering in the Cloud Solutions system, you gain access to manage the entire ecosystem from one account, which allows you to easily customize your experience based on your needs and business tasks.',

    faqItemPlatformCurrentStateTitle: 'What is the current development status of the product?',
    faqItemPlatformCurrentStateDescription:
      'We are currently in the active development phase of the Alpha version of our product. Our goal is to create a working prototype that will be provided to users for testing. We aim to gather feedback from early users to identify opportunities for further improvements and enhancements of the platform.',

    faqItemPlatformMainFunctionalityTitle: 'What main features are planned to be included in your product?',
    faqItemPlatformMainFunctionalityDescription:
      'In the Alpha version of our platform, we will include several key features: single sign-on technology that provides convenient access to all our products from one point; a control panel for managing the account, which allows users to easily adjust their settings and view usage statistics; and the Alpha version of our first product, which demonstrates the potential and capabilities of our platform.',

    faqItemPlatformBetaTestingTitle: 'How can I participate in the beta testing of the project?',
    faqItemPlatformBetaTestingDescription:
      'To apply for early access, please fill out the form on our website and briefly describe what interests you about our product. We will definitely consider your application. After the development of the Alpha version of the product is completed, we will send you an invitation to participate in the testing. Beta testing participants will receive free access to the platform for 12 months.',

    faqItemPlatformLongTermGoalsTitle: 'What are the long-term goals of our product?',
    faqItemPlatformLongTermGoalsDescription:
      'Our long-term goal is to develop a wide range of products for our ecosystem, aimed at the needs of both B2B and B2C clients. We plan to create solutions that will significantly simplify documentation management, as well as tools for effective content creation and other useful products. Our ultimate goal is to create a fully functional ecosystem that will enable startups to start and develop faster and more efficiently, solving a variety of current tasks.',

    faqItemPlatformNewsTitle: 'How can I learn more about the development of the project?',
    faqItemPlatformNewsDescription:
      'To stay updated on the latest developments and learn about the status of our product development, we invite you to follow us on social media. You can also visit our website, where news and updates are regularly published. Join our community to not miss any important announcements!',

    faqItemPlatformTechConversationsTitle: 'How can I contact the development team?',
    faqItemPlatformTechConversationsDescription:
      'We are currently actively working on the development of the Alpha version of our product and are not yet accepting inquiries to the development team. However, immediately after the launch of the public Alpha version, we will introduce a special Community section on our website. This section will provide an opportunity for each user not only to seek help or ask questions but also to submit their proposals and participate in voting on them. Follow our updates to stay informed about the launch.',

    // Contact us
    contactUsPageTitle: 'Contact us',
    contactUsPageDescription: 'Stay connected with us',
    contactUsPageLinkMailtoSupport: 'Customer support',
  },
};

export const uk = {
  translation: {
    // Main
    headerAppName: 'Cloud Solutions',
    headerSsoAuthLinkText: 'Sign In',
    headerHomeLinkText: 'огляд',
    headerNewsLinkText: 'новини',
    headerFaqLinkText: 'FAQ',
    headerAboutLinkText: 'про нас',

    // SEO
    seoCompanyName: 'Платформа Cloud Solutions',
    seoKeywords:
      'Стартапи Cloud Solutions, Cloud Solutions продукти для ІТ-компаній, Відкриті хмарні платформи, Інструменти для розробників, Безкоштовні рішення для розробки, Хмарне ПЗ для ІТ-професіоналів, Cloud Solutions open source, Хмарні рішення для програмістів, Хмарні технології для стартапів, Безкоштовні інструменти для open source, Продуктивні хмарні рішення, Хмарні додатки для ІТ-роботи, Хмарообчислення для бізнес-ефективності, Перспективні хмарні технології, Хмарні ресурси для розробників, Інноваційні продукти хмарообчислень, Cloud Solutions продукти для розробки ПЗ, Хмарні рішення для ІТ-ефективності, Хмарообчислення нового покоління, Стартапи хмарообчислень, Інструменти ефективності розробки в хмарі, Інструменти хмарної розробки ПЗ, Хмарні платформи для ІТ-інновацій',
    seoCompanyDescription:
      'Cloud Solutions — це стартап створений у 2024 році, який активно розвивається у напрямку розробки власних продуктів та надання безкоштовного доступу до наших розробок для Open Source. Наша мета — пропонувати наші продукти IT компаніям та розробникам, які прагнуть впливати на галузь та сприяти інноваціям у технологіях.',

    heroWidgetHeroTitle: 'Платформа, яка робить вашу роботу прибутковою та легшою',
    benefitWidgetHeroTitle: 'Створена для вашої команди, переконайтеся самі',
    benefitWidgetHeroDescription:
      'Створіть акаунт, щоб отримати доступ до екосистеми Cloud Solutions. Оберіть продукти, які вам потрібні, та платіть лише за ресурси, які ви використовуєте.',

    // Benefit Open Source
    benefitWidgetFreeForOpenSourceTitle: 'Безкоштовно для Open Source',
    benefitWidgetFreeForOpenSourceDescription:
      'Наша компанія підтримує розвиток відкритого програмного забезпечення, надаючи безкоштовний доступ до всіх функцій нашої платформи для проєктів відкритого коду. Ми віримо, що такий підхід не тільки допомагає зміцнювати спільноту, але й сприяє інноваціям у технологіях, дозволяючи розробникам зосередитись на створенні якісних продуктів та вдосконаленні рутинних процесів.',

    // Benefit Efficiency
    benefitWidgetEfficiencyTitle: 'Ефективність на кожному кроці',
    benefitWidgetEfficiencyDescription:
      'Наші продукти спрямовані на оптимізацію та автоматизацію рутинних процесів в розробці, що дозволяє IT компаніям та розробникам зекономити час і ресурси. Використовуючи передові технології, такі як штучний інтелект і машинне навчання, ми забезпечуємо високу якість та нові можливості для ваших проєктів.',

    // Benefit Leadership
    benefitWidgetLeadershipTitle: 'Лідерство через інновації',
    benefitWidgetLeadershipDescription:
      'Ми прагнемо стати лідерами у дослідженні та розробці, впроваджуючи нові стандарти у вирішенні рутинних завдань. Наші ініціативи, зорієнтовані на підтримку інновацій та стандартизацію підходів, допомагають забезпечити стабільність та відкритість у спільноті, що є ключем до взаємної довіри та обміну знаннями.',
  },

  // FAQ
  faqPageTitle: 'FAQs',
  faqPageDescription: 'Знайдіть відповіді на часті питання про наш продукт та послуги',

  // FAQs Items
  faqItemAboutPlatformAndSolutionsTitle: 'Що таке Cloud Solutions і які проблеми це допомагає вирішити?',
  faqItemAboutPlatformAndSolutionsDescription:
    "Cloud Solutions — це інтегрована платформа, що з'єднує різноманітні продукти, розроблені нашою компанією, у єдину екосистему. Основна перевага нашого підходу полягає у гнучкості вибору необхідних продуктів та легкості обміну даними між ними без потреби в додаткових налаштуваннях чи складних інтеграціях. Реєструючись в системі Cloud Solutions, ви отримуєте доступ до керування всією екосистемою з одного облікового запису, що дозволяє легко кастомізувати ваш досвід використання залежно від ваших потреб та бізнес-завдань.",

  faqItemPlatformCurrentStateTitle: 'Який поточний статус розробки продукту?',
  faqItemPlatformCurrentStateDescription:
    'Наразі ми знаходимось у фазі активної розробки Alpha-версії нашого продукту. Нашою метою є створення робочого прототипу, який буде наданий користувачам для тестування. Ми прагнемо отримати відгуки від ранніх користувачів, щоб ідентифікувати можливості для подальшого покращення та вдосконалення платформи.',

  faqItemPlatformMainFunctionalityTitle: 'Які основні функції планується включити у ваш продукт?',
  faqItemPlatformMainFunctionalityDescription:
    'У Alpha-версії нашої платформи ми включимо кілька ключових функцій: технологію єдиного входу, яка забезпечує зручний доступ до всіх наших продуктів з однієї точки; панель керування для управління обліковим записом, що дозволяє користувачам легко налаштовувати свої налаштування та переглядати статистику використання; а також Alpha-версію нашого першого продукту, який демонструє потенціал та можливості нашої платформи.',

  faqItemPlatformBetaTestingTitle: 'Як я можу взяти участь у бета-тестуванні проекту?',
  faqItemPlatformBetaTestingDescription:
    "Щоб подати заявку на ранній доступ, будь ласка, заповніть форму на нашому сайті і коротко опишіть, що саме вас зацікавило у нашому продукті. Ми обов'язково розглянемо вашу кандидатуру. Після завершення розробки Alpha-версії продукту, ми надішлемо вам запрошення для участі в тестуванні. Учасники бета-тестування отримають безкоштовний доступ до платформи на 12 місяців.",

  faqItemPlatformLongTermGoalsTitle: 'Які довгострокові цілі у нашого продукту?',
  faqItemPlatformLongTermGoalsDescription:
    'Нашою довгостроковою метою є розробка широкого спектру продуктів для нашої екосистеми, орієнтованих на потреби як B2B, так і B2C клієнтів. Ми плануємо створити рішення, що значно спростять ведення документації, а також інструменти для ефективного створення контенту та інші корисні продукти. Наша кінцева ціль — створити повнофункціональну екосистему, яка дозволить стартапам розпочинати та розвиватися швидше та ефективніше, вирішуючи різноманітні поточні задачі.',

  faqItemPlatformNewsTitle: 'Як я можу дізнатися більше про розвиток проекту?',
  faqItemPlatformNewsDescription:
    'Щоб бути в курсі останніх оновлень та дізнатися про статус розробки нашого продукту, запрошуємо вас слідкувати за нами у соціальних мережах. Також ви можете відвідувати наш сайт, де регулярно публікуються новини та оновлення. Приєднуйтесь до нашої спільноти, щоб не пропустити важливі анонси!',

  faqItemPlatformTechConversationsTitle: "Як можна зв'язатися з командою розробників?",
  faqItemPlatformTechConversationsDescription:
    'Наразі ми активно працюємо над розробкою Alpha-версії нашого продукту і поки що не приймаємо звернення до команди розробників. Однак, відразу після запуску публічної Alpha-версії, ми введемо спеціальний розділ Community на нашому сайті. Цей розділ надасть можливість кожному користувачу не лише звернутися за допомогою або задати питання, а й вносити свої пропозиції та брати участь у голосуванні за них. Слідкуйте за нашими оновленнями, щоб бути в курсі запуску.',
};

export const pl = {
  translation: {
    // Main
    headerAppName: 'Cloud Solutions',

    seoCompanyName: 'Platforma Cloud Solutions',
    seoKeywords:
      'Start-upy rozwiązań chmurowych, Produkty chmurowe dla firm IT, Otwarte platformy cloud, Narzędzia efektywności dla deweloperów, Własne produkty cloud, Technologie cloud dla start-upów, Darmowe rozwiązania chmurowe dla programistów, Usługi cloud dla poprawy efektywności pracy, Narzędzia deweloperskie w chmurze, Oprogramowanie cloud dla profesjonalistów IT, Produkty dla efektywności cloud computing, Narzędzia open source dla chmury, Innowacje platform cloud, Rozwiązania cloud dla deweloperów, Technologia cloud dla start-upów, Darmowe narzędzia cloud computing, Rozwiązania cloud dla produktywności, Aplikacje cloud dla pracy IT, Autorskie oprogramowanie cloud, Cloud computing dla efektywności biznesowej, Nowe technologie cloud, Zasoby cloud dla programistów, Innowacyjne produkty cloud computing, Usługi cloud dla rozwoju oprogramowania, Rozwiązania cloud dla efektywności IT, Cloud computing nowej generacji, Start-upy cloud computing, Narzędzia efektywności deweloperskiej w chmurze, Narzędzia deweloperskie cloud, Platformy cloud dla innowacji IT',
    seoCompanyDescription:
      'Cloud Solutions to dynamicznie rozwijający się startup skupiony na opracowywaniu własnych produktów i zapewnianiu bezpłatnego dostępu do naszych rozwiązań dla Open Source. Naszym celem jest stworzenie produktów, które są wykorzystywane przez różne firmy IT i programistów w celu zwiększenia efektywności pracy.',

    heroWidgetHeroTitle: 'Platforma, która czyni Twoją pracę opłacalną i łatwiejszą',
    benefitWidgetHeroTitle: 'Stworzona dla Twojego zespołu, przekonaj się sam',
    benefitWidgetHeroDescription:
      'Załóż konto, aby uzyskać dostęp do ekosystemu rozwiązań Cloud. Wybierz produkty, których potrzebujesz i płać tylko za zasoby, których używasz.',

    // Benefine Open Source
    benefitWidgetFreeForOpenSourceTitle: 'Zawsze darmowe dla Open Source',
    benefitWidgetFreeForOpenSourceDescription:
      'Nasza firma wspiera rozwój oprogramowania open-source, zapewniając darmowy dostęp do wszystkich funkcji naszej platformy dla projektów open-source. Wierzymy, że podejście to nie tylko pomaga wzmocnić społeczność, ale także sprzyja innowacjom technologicznym, pozwalając programistom skupić się na tworzeniu wysokiej jakości produktów i usprawnianiu rutynowych procesów.',

    // Benefit Efficiency
    benefitWidgetEfficiencyTitle: 'Efektywność na każdym kroku',
    benefitWidgetEfficiencyDescription:
      'Nasze produkty są zaprojektowane w celu optymalizacji i automatyzacji rutynowych procesów w rozwoju, co pozwala zaoszczędzić czas i zasoby dla firm IT i programistów. Korzystając z najnowszych technologii, takich jak sztuczna inteligencja i uczenie maszynowe, zapewniamy wysoką jakość i nowe możliwości dla Twoich projektów.',

    // Benefit Leadership
    benefitWidgetLeadershipTitle: 'Liderstwo poprzez innowacje',
    benefitWidgetLeadershipDescription:
      'Opis: Dążymy do bycia liderami w badaniach i rozwoju, ustanawiając nowe standardy w rozwiązywaniu rutynowych zadań. Nasze inicjatywy, skupione na wspieraniu innowacji i standaryzacji podejść, pomagają zapewnić stabilność i otwartość w społeczności, co jest kluczem do wzajemnego zaufania i wymiany wiedzy.',

    // FAQ
    faqPageTitle: 'FAQ',
    faqPageDescription: 'Znajdź odpowiedzi na najczęściej zadawane pytania dotyczące naszego produktu i usług',

    // FAQs Items
    faqItemAboutPlatformAndSolutionsTitle: 'Czym są Cloud Solutions i jakie problemy pomaga rozwiązać?',
    faqItemAboutPlatformAndSolutionsDescription:
      'Cloud Solutions to zintegrowana platforma, która łączy różnorodne produkty opracowane przez naszą firmę w jedną ekosystem. Główną zaletą naszego podejścia jest elastyczność w wyborze potrzebnych produktów i łatwość wymiany danych między nimi bez konieczności dodatkowych ustawień czy skomplikowanych integracji. Rejestrując się w systemie Cloud Solutions, uzyskujesz dostęp do zarządzania całym ekosystemem z jednego konta, co pozwala łatwo dostosować swoje doświadczenia zależnie od potrzeb i zadań biznesowych.',

    faqItemPlatformCurrentStateTitle: 'Jaki jest obecny stan rozwoju produktu?',
    faqItemPlatformCurrentStateDescription:
      'Obecnie znajdujemy się w aktywnej fazie rozwoju wersji Alpha naszego produktu. Naszym celem jest stworzenie działającego prototypu, który zostanie udostępniony użytkownikom do testowania. Zamierzamy zbierać opinie od wczesnych użytkowników, aby zidentyfikować możliwości dalszych ulepszeń i doskonalenia platformy.',

    faqItemPlatformMainFunctionalityTitle: 'Jakie główne funkcje są planowane do włączenia do produktu?',
    faqItemPlatformMainFunctionalityDescription:
      'W wersji Alpha naszej platformy włączymy kilka kluczowych funkcji: technologię jednokrotnego logowania, która zapewnia wygodny dostęp do wszystkich naszych produktów z jednego punktu; panel sterowania do zarządzania kontem, który umożliwia użytkownikom łatwe dostosowywanie ustawień i przeglądanie statystyk użytkowania; oraz wersję Alpha naszego pierwszego produktu, która demonstrować potencjał i możliwości naszej platformy.',

    faqItemPlatformBetaTestingTitle: 'Jak mogę wziąć udział w testowaniu beta projektu?',
    faqItemPlatformBetaTestingDescription:
      'Aby ubiegać się o wczesny dostęp, prosimy wypełnić formularz na naszej stronie internetowej i krótko opisać, co w naszym produkcie wzbudziło Twoje zainteresowanie. Na pewno rozpatrzymy Twoją kandydaturę. Po zakończeniu prac nad wersją Alpha produktu, wyślemy Ci zaproszenie do udziału w testach. Uczestnicy testów beta otrzymają bezpłatny dostęp do platformy na 12 miesięcy.',

    faqItemPlatformLongTermGoalsTitle: 'Jakie są długoterminowe cele naszego produktu?',
    faqItemPlatformLongTermGoalsDescription:
      'Naszym długoterminowym celem jest opracowanie szerokiej gamy produktów dla naszego ekosystemu, skierowanych zarówno na potrzeby klientów B2B, jak i B2C. Planujemy stworzyć rozwiązania, które znacząco uprością zarządzanie dokumentacją, jak również narzędzia do skutecznego tworzenia treści oraz inne użyteczne produkty. Naszym ostatecznym celem jest stworzenie w pełni funkcjonalnego ekosystemu, który umożliwi startupom szybsze i efektywniejsze rozpoczęcie działalności oraz rozwijanie się, rozwiązując różnorodne bieżące zadania.',

    faqItemPlatformNewsTitle: 'Jak mogę dowiedzieć się więcej o rozwoju projektu?',
    faqItemPlatformNewsDescription:
      'Aby być na bieżąco z najnowszymi rozwojami i dowiedzieć się o stanie rozwoju naszego produktu, zapraszamy do śledzenia nas w mediach społecznościowych. Możesz również odwiedzić naszą stronę internetową, na której regularnie publikowane są wiadomości i aktualizacje. Dołącz do naszej społeczności, aby nie przegapić żadnych ważnych ogłoszeń!',

    faqItemPlatformTechConversationsTitle: 'Jak mogę skontaktować się z zespołem deweloperów?',
    faqItemPlatformTechConversationsDescription:
      'Obecnie aktywnie pracujemy nad rozwojem wersji Alpha naszego produktu i na razie nie przyjmujemy zapytań do zespołu deweloperów. Jednakże, zaraz po uruchomieniu publicznej wersji Alpha, wprowadzimy specjalną sekcję Community na naszej stronie internetowej. Ta sekcja umożliwi każdemu użytkownikowi nie tylko zwrócić się o pomoc czy zadać pytania, ale również zgłaszać swoje propozycje i uczestniczyć w głosowaniu nad nimi. Śledź nasze aktualizacje, aby być na bieżąco z uruchomieniem.',
  },
};
