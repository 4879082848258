import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { SSOLogin } from '../feature/SSOLogin';
import { SSOSession } from '../feature/SSOSession';
import { container } from '../../../shared-kernel/provider/di';
import { SSOSessionStorage } from '../../../domain/sso';
import { toast } from '../../../shared-kernel/ui/Toast';

declare type AuthWidgetState = {
  isLoading: boolean;
  isError: boolean;
  token: string;
  ssoUrl: string;
};

export const AuthWidget = () => {
  const session = container.get<SSOSessionStorage>('SSOSessionStorage');
  const [query] = useSearchParams();
  const [state, setState] = useState<AuthWidgetState>({
    isLoading: false,
    isError: false,
    token: '',
    // TODO: Move to config
    ssoUrl: 'http://sso.cloud-solutions.llc?app=platform',
  });

  useEffect(() => {
    const token: string | null = query.get('token');
    if (token !== null) {
      setState({ ...state, token: token });
    }
  }, []);

  useEffect(() => {
    if (state.token.length > 0) {
      setState({ ...state, isLoading: true });
      session
        .provide(state.token)
        .then((s) => {
          toast('Session resolved. See in console', { variant: 'success', persist: true });
          console.log(s);
          setState({ ...state, isError: false, isLoading: false });
        })
        .catch((e) => {
          console.log(e);
          setState({ ...state, isError: true, isLoading: false });
          toast(e.message, { variant: 'error', persist: true });
        });
    }
  }, [state.token]);

  return (
    <>
      {state.token.length > 0 ? <SSOSession /> : <SSOLogin ssoLink={state.ssoUrl} />}
      {state.isError ? <Alert color={'error'}>{'Cant login. Something went wrong.'}</Alert> : ''}
    </>
  );
};
