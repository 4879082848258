import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Organization, WebSite } from 'schema-dts';
import { useTranslation } from 'react-i18next';

import { contactEmail, mainDomain, socialLinkTwitter, supportEmail, twitterSite } from '../../config';

export { HelmetProvider };

export declare type SEOProps = {
  title: string;
  description: string;
  keywords: string;
  previewImage?: string;
  documents: Array<object>;
};

export const SeoWidget = ({ title, description, keywords, previewImage, documents }: SEOProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<Organization>({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: t('seoCompanyName'),
            description: t('seoCompanyDescription'),
            url: mainDomain,
            logo: mainDomain + '/logo.png',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                email: supportEmail,
                contactType: 'customer support',
                areaServed: 'WW',
                availableLanguage: ['English', 'Ukrainian'],
              },
              {
                '@type': 'ContactPoint',
                email: contactEmail,
                contactType: 'general contact',
                areaServed: 'WW',
                availableLanguage: ['English', 'Ukrainian'],
              },
            ],
            sameAs: [socialLinkTwitter],
          }),
          helmetJsonLdProp<WebSite>({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: mainDomain,
            mainEntityOfPage: {
              '@type': 'CollectionPage',
              breadcrumb: {
                '@type': 'BreadcrumbList',
                itemListElement: [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    item: {
                      '@id': mainDomain,
                      name: 'Overview',
                    },
                  },
                  {
                    '@type': 'ListItem',
                    position: 2,
                    item: {
                      '@id': mainDomain + '/about',
                      name: 'About us',
                    },
                  },
                ],
              },
            },
          }),
          ...documents,
        ]}
      >
        <title>{t(title)}</title>
        <meta
          name="description"
          content={t(description)}
        />
        <meta
          name="keywords"
          content={t(keywords)}
        />
        {/* Open Graph метатеги для Facebook, Telegram та інших месенджерів */}
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content={mainDomain}
        />
        <meta
          property="og:title"
          content={t(title)}
        />
        <meta
          property="og:description"
          content={t(description)}
        />
        <meta
          property="og:image"
          content={mainDomain + '/logo.png'}
        />
        {/* Twitter Card метатеги */}
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content={twitterSite}
        />
        <meta
          name="twitter:title"
          content={t(title)}
        />
        <meta
          name="twitter:description"
          content={t(description)}
        />
        <meta
          name="twitter:image"
          content={previewImage || mainDomain + '/logo.png'}
        />
        {/*Метатеги для контролю попереднього перегляду зображення (для деяких платформ)*/}
        <meta
          name="image"
          content={previewImage || mainDomain + '/logo.png'}
        />
        <link
          rel="image_src"
          content={previewImage || mainDomain + '/logo.png'}
        />
        {/* Додаткові налаштування */}
        <meta
          property="og:site_name"
          content={t('seoCompanyName')}
        />
        <meta
          property="og:locale"
          content="en_US"
        />
      </Helmet>
    </>
  );
};
