import { useCallback, useEffect, useRef, useSyncExternalStore } from 'react';

const dispatchStorageEvent = (key, newValue) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
};

export const getLocalStorageItem = (key) => {
  const storageItem = window.localStorage.getItem(key);
  return storageItem && JSON.parse(storageItem);
};

export const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

export const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

const useLocalStorageSubscribe = (callback) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

export function useLocalStorage(key, initialValue) {
  const getSnapshot = () => getLocalStorageItem(key);

  const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot);
  const state = store || initialValue;

  const memoKey = useRef(key);
  memoKey.current = key;

  const setState = useCallback((nextState) => {
    try {
      if (nextState === undefined || nextState === null) {
        removeLocalStorageItem(memoKey.current);
      } else {
        setLocalStorageItem(memoKey.current, nextState);
      }
    } catch (e) {
      console.warn(e);
    }
  }, []);

  useEffect(() => {
    if (getLocalStorageItem(key) === null && initialValue !== undefined) {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [state, setState];
}
