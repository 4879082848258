import React from 'react';
import { Box, Typography } from '@mui/material';

declare type QAItemProps = {
  title: string;
  description: string;
};

export const QAItem = ({ title, description }: QAItemProps) => {
  return (
    <Box
      component={'article'}
      mb={{
        xs: 5,
        md: 10,
        lg: 10,
      }}
      data-aos="fade-up"
    >
      <Typography
        variant={'h2'}
        component={'h2'}
        mb={2}
        fontSize={{
          xs: '2rem',
          sm: '2rem',
          md: '2.2rem',
          lg: '2.5rem',
        }}
        fontWeight={'bold'}
      >
        {title}
      </Typography>
      <Typography
        component={'p'}
        variant={'subtitle1'}
        color={'#848d97'}
      >
        {description}
      </Typography>
    </Box>
  );
};
