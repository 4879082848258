import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

declare type SSOLoginProps = {
  ssoLink: string;
};

export const SSOLogin = ({ ssoLink }: SSOLoginProps) => {
  const { t } = useTranslation();
  const redirect = () => (window.location.href = ssoLink);

  return (
    <>
      <Typography>{t('ssoLoginMainTitleText')}</Typography>
      <Typography variant={'subtitle1'}>{t('ssoLoginHelpText')}</Typography>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={redirect}
      >
        {t('ssoLoginBtn')}
      </Button>
    </>
  );
};
