import auth from '../account/auth';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../hooks/useLocalStorage';

const dispatchUnauthorized = () => document.dispatchEvent(new CustomEvent('unauthorized'));

let refreshingToken = false;
let pendingRequests = [];

export const refreshToken = (originalRequest, http) => {
  const refreshToken = getLocalStorageItem('platform_refresh_token');
  if (!refreshToken) return dispatchUnauthorized();

  if (!refreshingToken) {
    refreshingToken = true;

    auth
      .refreshToken({ refreshToken })
      .then(({ data: { data } }) => {
        setLocalStorageItem('platform_auth_token', data.accessToken);
        setLocalStorageItem('platform_refresh_token', data.refreshToken);

        refreshingToken = false;

        pendingRequests.forEach((pendingRequest) => pendingRequest(data.accessToken));
        pendingRequests = [];
      })
      .catch(() => {
        removeLocalStorageItem('platform_auth_token');
        removeLocalStorageItem('platform_refresh_token');
        dispatchUnauthorized();
      });
  }

  return new Promise((resolve) => {
    pendingRequests.push((authToken) => {
      originalRequest.headers['Authorization'] = `Bearer ${authToken}`;
      resolve(http(originalRequest));
    });
  });
};
