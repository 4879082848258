import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import './assets/index.scss';

const NotFoundPage = () => {
  return (
    <div className={'animation-box'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        height={'100vh'}
      >
        <Stack direction={'column'}>
          <Typography variant={'h1'}>{'Whoops! Looks like page not found'}</Typography>
          <Link
            component={RouterLink}
            to={'/'}
          >
            {'Go to home'}
          </Link>
        </Stack>
      </Box>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
};

export default NotFoundPage;
