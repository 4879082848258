import { Container } from 'inversify';
import {
  SDKMessageBuilder,
  SDKJSONMessageBuilder,
  SDKSessionStorage,
  SDKStorageFactory,
} from '@cloud-solutions-llc/core-ui-sdk';

import { SSOSessionRemoteStorage, SSOSessionStorage } from '../../domain/sso';

const container = new Container();
container.bind<SDKMessageBuilder>('SDKMessageBuilder').to(SDKJSONMessageBuilder);
container.bind<SDKSessionStorage>('SDKSessionStorage').toConstantValue(SDKStorageFactory.session());
container.bind<SSOSessionStorage>('SSOSessionStorage').to(SSOSessionRemoteStorage);

export { container };
