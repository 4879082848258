import { SDKSession } from '@cloud-solutions-llc/core-ui-sdk';
import { injectable } from 'inversify';

import { SSOSessionStorage } from '../../domain/repository/SSOSessionStorage';
import api from '../../../../shared-kernel/api';

@injectable()
export class SSOSessionRemoteStorage implements SSOSessionStorage {
  async provide(token: string): Promise<SDKSession> {
    const response = await api.sso.session(token);

    return new SDKSession(response.data?.data?.accessToken ?? '', response.data?.data?.refreshToken ?? '');
  }
}
