import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { helmetJsonLdProp } from 'react-schemaorg';
import { FAQPage as JSONLDFaqPage } from 'schema-dts';

import { SeoWidget } from '../../shared-kernel/ui/seo';

import { QAItem } from './feature/QAItem';

const FAQPage = () => {
  const { t } = useTranslation();

  const items = [
    {
      slug: 'faqItemAboutPlatformAndSolutions',
      title: 'faqItemAboutPlatformAndSolutionsTitle',
      description: 'faqItemAboutPlatformAndSolutionsDescription',
    },
    {
      slug: 'faqItemPlatformCurrentState',
      title: 'faqItemPlatformCurrentStateTitle',
      description: 'faqItemPlatformCurrentStateDescription',
    },
    {
      slug: 'faqItemPlatformMainFunctionality',
      title: 'faqItemPlatformMainFunctionalityTitle',
      description: 'faqItemPlatformMainFunctionalityDescription',
    },
    {
      slug: 'faqItemPlatformBetaTesting',
      title: 'faqItemPlatformBetaTestingTitle',
      description: 'faqItemPlatformBetaTestingDescription',
    },
    {
      slug: 'faqItemPlatformLongTermGoals',
      title: 'faqItemPlatformLongTermGoalsTitle',
      description: 'faqItemPlatformLongTermGoalsDescription',
    },
    {
      slug: 'faqItemPlatformNews',
      title: 'faqItemPlatformNewsTitle',
      description: 'faqItemPlatformNewsDescription',
    },
    {
      slug: 'faqItemPlatformTechConversations',
      title: 'faqItemPlatformTechConversationsTitle',
      description: 'faqItemPlatformTechConversationsDescription',
    },
  ];

  return (
    <>
      <SeoWidget
        title={t('seoFaqsPageTitle')}
        description={t('seoCompanyDescription')}
        keywords={t('seoCompanyKeywords')}
        documents={[
          helmetJsonLdProp<JSONLDFaqPage>({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: items.map((item) => ({
              '@type': 'Question',
              name: t(item.title),
              acceptedAnswer: {
                '@type': 'Answer',
                text: t(item.description),
              },
            })),
          }),
        ]}
      />
      <Container maxWidth={'xl'}>
        <Stack
          width={{
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
          }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Stack
            data-aos="fade-up"
            width={{
              xs: '100%',
              sm: '80%',
              md: '50%',
              lg: '50%',
            }}
            my={{
              xs: 5,
            }}
            mb={{
              xs: 5,
              md: 10,
              lg: 10,
            }}
          >
            <Typography
              variant={'h1'}
              component={'h1'}
              fontWeight={'bold'}
              fontSize={{
                xs: '2.5rem',
                sx: '2.5rem',
                md: '3rem',
                lg: '4rem',
              }}
              mb={{
                xs: 1,
                md: 2,
                lg: 2,
              }}
            >
              {t('faqPageTitle')}
            </Typography>
            <Typography
              variant={'subtitle1'}
              component={'p'}
              color={'#848d97'}
            >
              {t('faqPageDescription')}
            </Typography>
          </Stack>
          <Box
            width={{
              xs: '100%',
              sm: '80%',
              md: '50%',
              lg: '50%',
            }}
          >
            {items.map((item) => (
              <QAItem
                key={item.slug}
                title={t(item.title)}
                description={t(item.description)}
              />
            ))}
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default FAQPage;
