import { makeAutoObservable, runInAction } from 'mobx';
import { SDKProduct } from '@cloud-solutions-llc/core-ui-sdk';

import { en, pl, uk } from '../shared-kernel/provider/i18n/static-locales';
import i18n from '../shared-kernel/provider/i18n';

export class Localizations {
  private _code = 'en';

  constructor() {
    makeAutoObservable(this);
  }

  public init(): void {
    runInAction(() => {
      let code = localStorage.getItem(SDKProduct.Platform + '_language');

      if (code && this._code !== code) {
        this.changeLocale(code);
      } else {
        code = 'en';
        this.changeLocale('code');
      }

      this.changeLocale(code);
    });
  }

  public get locale() {
    return this._code;
  }

  public changeLocale(code: string) {
    this._code = code;
    localStorage.setItem(SDKProduct.Platform + '_language', code);

    i18n.changeLanguage(code, () => {
      switch (this._code) {
        case 'en':
          return en;
        case 'uk':
          return uk;
        case 'pl':
          return pl;
      }
    });
  }
}

export default new Localizations();
