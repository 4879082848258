import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, uk, pl } from './static-locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: process.env.NODE_ENV === 'development',
      fallbackLng: 'pl',
      supportedLngs: ['en', 'uk', 'pl'],
      detection: {
        // order: ['localStorage', 'navigator'],
        // caches: ['localStorage'],
      },
      resources: {
        en,
        uk,
        pl,
      },
    },
    undefined
  );

export default i18n;
