import http, { Document } from './http';
import { SSOSession } from './type/SSOSession';

export const sso = {
  session: (token: string) =>
    http.post<Document<SSOSession>>('/api/v1/sso/session', {
      data: {
        token: token,
      },
    }),
};
