import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// import PublicLayout from './shared-kernel/ui/layouts/PublicLayout';
// import ProtectedLayout from './shared-kernel/ui/layouts/ProtectedLayout';
// import { DashboardPage } from './presentation/dashboard';
// eslint-disable-next-line import/order
// import { ArticlePage, BlogPage } from './presentation/blog';

// const WelcomePage = lazy(() => import('./presentation/welcome'));
// import { OrganizationSettingsPage, OrganizationsPage } from './presentation/organization';
import { App } from './App';
import NotFoundPage from './presentation/error/NotFoundPage';
import SignInPage from './presentation/auth/SignInPage';
import AboutPage from './presentation/about/AboutPage';
import FAQPage from './presentation/faq/FAQPage';
import ContactUsPage from './presentation/contact-us/ContactUsPage';
import WelcomePage from './presentation/welcome/WelcomePage';

const PublicLayout = lazy(() => import('./shared-kernel/ui/layouts/PublicLayout'));
// import { BillingPage } from './presentation/billing/BillingPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <PublicLayout />,
        children: [
          {
            index: true,
            element: <WelcomePage />,
          },
          {
            path: '/auth/sso',
            element: <SignInPage />,
          },
          // {
          //   path: '/news',
          //   element: <BlogPage />,
          // },
          // {
          //   path: '/articles/:id',
          //   element: <ArticlePage />,
          // },
          {
            path: '/about',
            element: <AboutPage />,
          },
          {
            path: '/faqs',
            element: <FAQPage />,
          },
          {
            path: '/contact-us',
            element: <ContactUsPage />,
          },
        ],
      },
      // {
      //   element: <ProtectedLayout />,
      //   children: [
      //     {
      //       path: '/dashboard',
      //       element: <DashboardPage />,
      //     },
      //     {
      //       path: '/organizations',
      //       element: <OrganizationsPage />,
      //     },
      //     {
      //       path: '/organizations/:code/settings',
      //       element: <OrganizationSettingsPage />,
      //     },
      //     {
      //       path: '/billings',
      //       element: <BillingPage />,
      //     },
      //   ],
      // },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);
