import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HeroSlideWidget = () => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      sx={{
        minWidth: '100%',
        minHeight: '80vh',
      }}
      alignItems={'center'}
      justifyContent={'center'}
      data-aos="fade-up"
      className={'hero-box'}
    >
      <Typography
        variant={'h1'}
        maxWidth={{
          xs: '22rem',
          sm: '35rem',
          md: '50rem',
          lg: '61.25rem',
          xl: '66.25rem',
        }}
        textAlign={{
          xs: 'center',
          md: 'center',
        }}
        fontSize={{
          xs: '2.5rem',
          sm: '3rem',
          md: '5rem',
          lg: '5rem',
          xl: '6rem',
        }}
        fontFamily={'Manrope'}
        fontWeight={'800'}
        className={'herro-text'}
      >
        {t('heroWidgetHeroTitle')}
      </Typography>
    </Box>
  );
};

export default HeroSlideWidget;
