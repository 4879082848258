import auth from './account/auth';
import users from './account/users';
import localization from './localization';
// Utils
import { isCancelRequest, setRequestHeaders } from './http';
import { parseError } from './utils/parse-error';
import { retry } from './utils';
import { sso } from './Sso';

export default {
  auth,
  users,
  localization,
  sso: sso,
};

export { isCancelRequest, setRequestHeaders, parseError, retry };
