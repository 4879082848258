import React from 'react';
import { useTranslation } from 'react-i18next';

import { SeoWidget } from '../../shared-kernel/ui/seo';

import HeroSlideWidget from './widget/HeroSlideWidget';
import StartNowSlideWidget from './widget/StartNowSlideWidget';
import BenefitsSlideWidget from './widget/BenefitsSlideWidget';
import './assets/index.css';

const WelcomePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <SeoWidget
        title={t('seoWelcomePageTitle')}
        description={t('seoCompanyDescription')}
        keywords={t('seoCompanyKeywords')}
        documents={[]}
      />
      <HeroSlideWidget />
      <BenefitsSlideWidget />
      <StartNowSlideWidget />
    </>
  );
};

export default WelcomePage;
