import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SeoWidget } from '../../shared-kernel/ui/seo';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SeoWidget
        title={t('seoAboutPageTitle')}
        description={t('seoCompanyDescription')}
        keywords={t('seoCompanyKeywords')}
        documents={[]}
      />
      {/* Welcome */}
      <Box sx={{ minWidth: '100%' }}>
        <Container maxWidth="xl">
          <Box
            pt={{
              xs: 5,
              md: 20,
            }}
            pb={{
              xs: 2,
              sx: 5,
              md: 20,
            }}
            display={'flex'}
            flexDirection={{
              xs: 'column',
              sm: 'row',
              md: 'row',
            }}
            alignItems={'start'}
            justifyContent={'center'}
          >
            <Stack
              position={{
                xs: 'relative',
                sm: 'sticky',
                md: 'sticky',
              }}
              top={{
                xs: 100,
                sm: '20%',
                md: '20%',
              }}
              width={{
                xs: '100%',
                sm: '45%',
                md: '45%',
                lg: '45%',
              }}
              spacing={{
                xs: 2,
                md: 4,
              }}
              textAlign={'left'}
              data-aos="fade-up"
            >
              <Typography
                variant={'h3'}
                textAlign={{
                  xs: 'left',
                  md: 'left',
                }}
                fontSize={{
                  xs: '2.5rem',
                  sx: '2.5rem',
                  md: '3rem',
                  lg: '3rem',
                }}
                width={{
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                }}
              >
                {t('aboutWelcomeSectionSubTitle')}
              </Typography>
              <Typography
                variant={'h3'}
                textAlign={{
                  xs: 'left',
                  md: 'left',
                }}
                fontSize={{
                  xs: '2.5rem',
                  sx: '2.5rem',
                  md: '3rem',
                  lg: '4rem',
                }}
                width={{
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                }}
                fontWeight={'bold'}
              >
                {t('aboutWelcomeSectionTitle')}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={6}
              sx={{ position: 'relative' }}
              width={{
                xs: '100%',
                sm: '45%',
                md: '45%',
                lg: '45%',
              }}
              ml={{
                xs: 0,
                sx: 0,
                md: '5%',
                lg: '5%',
              }}
            >
              <Box
                py={{
                  xs: 20,
                  md: 45,
                }}
                textAlign={'right'}
              >
                <Typography
                  variant={'subtitle1'}
                  component={'p'}
                  data-aos="fade-up"
                  textAlign={{
                    xs: 'left',
                    md: 'left',
                  }}
                  fontSize={{
                    md: '1.375rem',
                    lg: '1.375rem',
                  }}
                >
                  {t('aboutWelcomeSectionDescription')}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>

      {/* Mission*/}
      <Box sx={{ minWidth: '100%' }}>
        <Container maxWidth="xl">
          <Box
            py={20}
            display={'flex'}
            flexDirection={{
              xs: 'column-reverse',
              sm: 'row',
              md: 'row',
            }}
            alignItems={'start'}
            justifyContent={'center'}
            px={{
              xs: 0,
              lg: 6,
            }}
            pt={{
              xs: 2,
              sx: 5,
              md: 20,
            }}
            pb={{
              xs: 5,
              md: 20,
            }}
          >
            <Stack
              direction={'row'}
              sx={{ position: 'relative' }}
              maxWidth={{
                xs: '100%',
                sm: '50%',
                md: '50%',
                lg: '50%',
              }}
            >
              <Box
                py={{
                  xs: 20,
                  md: 45,
                }}
              >
                <Typography
                  variant={'subtitle1'}
                  component={'p'}
                  data-aos="fade-up"
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  textAlign={{
                    xs: 'left',
                    md: 'left',
                  }}
                  fontSize={{
                    md: '1.375rem',
                    lg: '1.375rem',
                  }}
                >
                  {t('aboutWelcomeMissionDescription')}
                </Typography>
              </Box>
            </Stack>
            <Stack
              position={{
                xs: 'relative',
                sm: 'sticky',
                md: 'sticky',
              }}
              sx={{ top: 100 }}
              top={{
                xs: '20%',
                sm: '20%',
                md: '20%',
              }}
              width={{
                xs: '100%',
                sm: '45%',
                md: '45%',
                lg: '45%',
              }}
              ml={{
                xs: 0,
                sx: 0,
                md: '5%',
                lg: '5%',
              }}
              spacing={{
                xs: 2,
                md: 4,
              }}
              data-aos="fade-up"
            >
              <Typography
                variant={'h3'}
                textAlign={{
                  xs: 'right',
                  md: 'right',
                }}
                fontSize={{
                  xs: '4rem',
                  sx: '2.5rem',
                  md: '5rem',
                  lg: '7rem',
                }}
                width={{
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                }}
                fontWeight={'bold'}
                style={{
                  color: '#000',
                }}
                className={'text-stroke'}
              >
                {t('aboutWelcomeMissionTitle')}
              </Typography>
            </Stack>
          </Box>
        </Container>
      </Box>

      {/* Vision*/}
      <Box sx={{ minWidth: '100%' }}>
        <Container maxWidth="xl">
          <Box
            pt={{
              xs: 5,
              md: 20,
            }}
            pb={{
              xs: 5,
              md: 5,
            }}
            display={'flex'}
            flexDirection={{
              xs: 'column',
              sm: 'row',
              md: 'row',
            }}
            alignItems={'start'}
            justifyContent={'center'}
          >
            <Stack
              position={{
                xs: 'relative',
                sm: 'sticky',
                md: 'sticky',
              }}
              top={{
                xs: '20%',
                sm: '20%',
                md: '20%',
              }}
              width={{
                xs: '100%',
                sm: '45%',
                md: '45%',
                lg: '45%',
              }}
              spacing={{
                xs: 2,
                md: 4,
              }}
              textAlign={'left'}
              data-aos="fade-up"
            >
              <Typography
                variant={'h3'}
                textAlign={{
                  xs: 'left',
                  md: 'left',
                }}
                fontSize={{
                  xs: '4rem',
                  sx: '2.5rem',
                  md: '5rem',
                  lg: '7rem',
                }}
                width={{
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                }}
                fontWeight={'bold'}
                style={{
                  color: '#000',
                }}
                className={'text-stroke'}
              >
                {t('aboutWelcomeVisionTitle')}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              sx={{ position: 'relative' }}
              width={{
                xs: '100%',
                sm: '45%',
                md: '45%',
                lg: '45%',
              }}
              ml={{
                xs: 0,
                sx: 0,
                md: '5%',
                lg: '5%',
              }}
            >
              <Box
                py={{
                  xs: 8,
                  md: 45,
                }}
                textAlign={'right'}
              >
                <Typography
                  variant={'subtitle1'}
                  component={'p'}
                  data-aos="fade-up"
                  textAlign={{
                    xs: 'left',
                    md: 'left',
                  }}
                  fontSize={{
                    md: '1.375rem',
                    lg: '1.375rem',
                  }}
                >
                  {t('aboutWelcomeVisionDescription')}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AboutPage;
