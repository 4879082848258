import axios, { AxiosHeaders, isCancel } from 'axios';

import { getLocalStorageItem } from '../../hooks/useLocalStorage';

import { refreshToken } from './utils/refresh-token';

export declare type Document<T> = {
  data: T;
};

export declare type Collection<T> = {
  data: T;
  meta: {
    cursor: {
      perPage: number;
      next: string | number;
    };
  };
};

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? window.location.origin : process.env.REACT_APP_BASE_URL,
});

// Request interceptors
http.interceptors.request.use(
  function (config) {
    const authToken = getLocalStorageItem('platform_auth_token');

    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptors
http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      return refreshToken(error.response.config, http);
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;

// Utils
export const isCancelRequest = isCancel;

export const setRequestHeaders = (
  key: string | number,
  value: string | number | boolean | AxiosHeaders | string[] | null | undefined
) => {
  if (!key || !value) return;

  http.defaults.headers.common[key] = value;
};
