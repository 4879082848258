import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SDKProduct, SDKTheme, SDKThemeBuilder } from '@cloud-solutions-llc/core-ui-sdk';
import { HelmetProvider } from 'react-helmet-async';

import { ToastContainer } from './shared-kernel/ui/Toast';
import store from './store';

export function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    store.localization.init();
  }, []);

  return (
    <ThemeProvider theme={createTheme(SDKThemeBuilder(SDKProduct.Platform, SDKTheme.Dark), [SDKTheme.Dark])}>
      <HelmetProvider>
        <Suspense>
          <Outlet />
        </Suspense>
      </HelmetProvider>
      <ToastContainer />
      <CssBaseline />
    </ThemeProvider>
  );
}
