import React from 'react';
import Grow from '@mui/material/Grow';
import { SnackbarProvider } from 'notistack';

const anchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
};

const ToastContainer = () => (
  <SnackbarProvider
    TransitionComponent={Grow}
    autoHideDuration={5000}
    anchorOrigin={anchorOrigin}
    hideIconVariant
  />
);

// API reference: https://notistack.com/api-reference
export default ToastContainer;
