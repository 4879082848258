import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Link, List, ListItem, Stack, Typography } from '@mui/material';
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';

import { SeoWidget } from '../../shared-kernel/ui/seo';
import { supportEmail } from '../../shared-kernel/config';

const ContactUsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SeoWidget
        title={t('seoContactUsPageTitle')}
        description={t('seoCompanyDescription')}
        keywords={t('seoCompanyKeywords')}
        documents={[]}
      />
      <Container maxWidth={'xl'}>
        <Stack
          width={{
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
          }}
          minHeight={{
            xs: '80vh',
            sm: '80vh',
            md: '80vh',
            lg: '80vh',
          }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Stack
            data-aos="fade-up"
            width={{
              xs: '100%',
              sm: '80%',
              md: '50%',
              lg: '50%',
            }}
            my={{
              xs: 5,
            }}
            mb={{
              xs: 5,
              md: 5,
              lg: 5,
            }}
          >
            <Typography
              variant={'h1'}
              component={'h1'}
              fontWeight={'bold'}
              fontSize={{
                xs: '2.5rem',
                sx: '2.5rem',
                md: '3rem',
                lg: '4rem',
              }}
              mb={{
                xs: 1,
                md: 2,
                lg: 2,
              }}
            >
              {t('contactUsPageTitle')}
            </Typography>
            <Typography
              variant={'subtitle1'}
              component={'p'}
              color={'#848d97'}
            >
              {t('contactUsPageDescription')}
            </Typography>
          </Stack>
          <Box
            data-aos="fade-up"
            width={{
              xs: '100%',
              sm: '80%',
              md: '50%',
              lg: '50%',
            }}
          >
            <List>
              <ListItem style={{ paddingLeft: '0', paddingRight: '0' }}>
                <Link
                  href={'mailto:' + supportEmail}
                  fontSize={{
                    xs: '1.5rem',
                    sm: '1.5rem',
                    md: '1.5rem',
                    lg: '1.5rem',
                  }}
                  color={'#fff'}
                  fontWeight={'bold'}
                >
                  {t('contactUsPageLinkMailtoSupport')} <TransitEnterexitIcon />
                </Link>
              </ListItem>
            </List>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default ContactUsPage;
