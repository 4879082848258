import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BenefitsSlideWidget = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ minWidth: '100%' }}>
      <Container maxWidth="xl">
        <Box
          pt={{
            xs: 5,
            md: 20,
          }}
          pb={{
            xs: 5,
            md: 20,
          }}
          display={'flex'}
          flexDirection={{
            xs: 'column',
            sm: 'row',
            md: 'row',
          }}
          alignItems={'start'}
          justifyContent={'center'}
        >
          <Stack
            position={{
              xs: 'relative',
              sm: 'sticky',
              md: 'sticky',
            }}
            top={{
              xs: 100,
              sm: '20%',
              md: '20%',
            }}
            spacing={4}
            data-aos="fade-up"
            maxWidth={{
              xs: '100%',
              sm: '45%',
              md: '45%',
              lg: '45%',
            }}
          >
            <Typography
              variant={'h2'}
              textAlign={{
                xs: 'left',
                md: 'left',
              }}
              fontSize={{
                xs: '2.5rem',
                sx: '2.5rem',
                md: '3rem',
                lg: '4rem',
              }}
              maxWidth={{
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',
              }}
              fontWeight={'bold'}
              fontFamily={'Montserrat Alternates'}
              className={'benefit-title'}
            >
              {t('benefitWidgetHeroTitle')}
            </Typography>
            <Typography
              variant={'subtitle1'}
              component={'p'}
              data-aos="fade-up"
              color={'#848d97'}
              maxWidth={{
                xs: '100%',
                md: '100%',
                lg: '100%',
              }}
              textAlign={{
                xs: 'left',
                md: 'left',
              }}
              fontSize={{
                md: '1.2rem',
                lg: '1.2rem',
              }}
            >
              {t('benefitWidgetHeroDescription')}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            spacing={6}
            mt={{
              xs: 10,
              md: 40,
            }}
            ml={{
              xs: 0,
              md: '5%',
              lg: '5%',
            }}
            sx={{ position: 'relative' }}
            maxWidth={{
              xs: '100%',
              sm: '50%',
              md: '50%',
              lg: '50%',
            }}
          >
            <Box>
              <Box
                my={{
                  xs: 10,
                  md: 20,
                }}
                data-aos="fade-up"
              >
                <Stack
                  direction={'row'}
                  alignItems={'end'}
                  my={4}
                  spacing={4}
                >
                  <Typography
                    variant={'h2'}
                    fontSize={{
                      xs: '3rem',
                      md: '4rem',
                    }}
                    fontFamily={'Manrope'}
                    fontWeight={'bold'}
                    className={'text-gradient'}
                    style={{ backgroundImage: `linear-gradient(90deg, #42d392, #fff)` }}
                  >
                    {t('benefitWidgetFreeForOpenSourceTitle')}
                  </Typography>
                </Stack>
                <Typography
                  component={'p'}
                  variant={'subtitle1'}
                >
                  {t('benefitWidgetFreeForOpenSourceDescription')}
                </Typography>
              </Box>

              <Box
                my={{
                  xs: 10,
                  md: 20,
                }}
                data-aos="fade-up"
              >
                <Stack
                  direction={'row'}
                  alignItems={'end'}
                  my={4}
                  spacing={4}
                >
                  <Typography
                    variant={'h2'}
                    fontSize={{
                      xs: '3rem',
                      md: '4rem',
                    }}
                    fontFamily={'Manrope'}
                    fontWeight={'bold'}
                    className={'text-gradient'}
                    style={{ backgroundImage: `linear-gradient(90deg, rgb(202, 119, 162), rgb(155, 129, 249))` }}
                  >
                    {t('benefitWidgetEfficiencyTitle')}
                  </Typography>
                </Stack>
                <Typography
                  component={'p'}
                  variant={'subtitle1'}
                >
                  {t('benefitWidgetEfficiencyDescription')}
                </Typography>
              </Box>

              <Box
                my={{
                  xs: 10,
                  md: 20,
                }}
                data-aos="fade-up"
              >
                <Stack
                  direction={'row'}
                  alignItems={'end'}
                  my={4}
                  spacing={4}
                >
                  <Typography
                    variant={'h2'}
                    fontSize={{
                      xs: '3rem',
                      md: '4rem',
                    }}
                    fontFamily={'Manrope'}
                    fontWeight={'bold'}
                    className={'text-gradient'}
                    style={{ backgroundImage: `linear-gradient(90deg, #e2c172, #fff)` }}
                  >
                    {t('benefitWidgetLeadershipTitle')}
                  </Typography>
                </Stack>
                <Typography
                  component={'p'}
                  variant={'subtitle1'}
                >
                  {t('benefitWidgetLeadershipDescription')}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default BenefitsSlideWidget;
