import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StartNowSlideWidget = () => {
  const { t } = useTranslation();

  return (
    <Stack
      display={'flex'}
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        background: `radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)`,
      }}
      spacing={2}
      alignItems={'center'}
      justifyContent={'center'}
      data-aos="fade-up"
    >
      <Typography
        variant={'h3'}
        maxWidth={{
          xs: '22rem',
          md: '25rem',
          lg: '66.25rem',
        }}
        textAlign={{
          xs: 'center',
          md: 'center',
        }}
        fontSize={{
          md: '3rem',
          lg: '4rem',
        }}
        fontWeight={'bold'}
      >
        {t('widgetUseNowTitle')}
      </Typography>
      <Typography
        lineHeight={1.5}
        variant={'h3'}
        maxWidth={{
          xs: '22rem',
          md: '25rem',
          lg: '30rem',
        }}
        textAlign={{
          xs: 'center',
          md: 'center',
        }}
        fontSize={{
          md: '1rem',
          lg: '1rem',
        }}
      >
        {t('widgetUseNowDescription')}
      </Typography>
      <Button variant={'contained'}>{t('widgetUseNowBtnCreateAccount')}</Button>
    </Stack>
  );
};

export default StartNowSlideWidget;
